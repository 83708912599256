@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.modal {
  background-color: colors.$white;
  flex-direction: column;
  height: 100vh;
  padding: 15px;
  width: 100vw;
  overflow-y: auto;

  @media screen and (min-width: vars.$medium) {
    border-radius: 10px;
    height: fit-content;
    min-width: 500px;
    max-width: fit-content;
    max-height: 100vh;
  }

  @media screen and (min-width: vars.$extra-large) {
    padding: vars.$gutter;
  }

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    background: colors.$background;
    padding: vars.$nodeSpacing;

    @media screen and (min-width: vars.$medium) {
      min-width: 500px;
      max-width: fit-content;
      left: initial;
      margin-left: -15px;
      border-radius: 10px 10px 0 0;
    }

    @media screen and (min-width: vars.$extra-large) {
      background: none;
      min-width: none;
      max-width: none;
      width: auto;
      padding: 0;
      position: initial;
    }

    .title {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      gap: vars.$nodeSpacing;
      align-items: center;
      font-stretch: condensed;
      width: 100%;
      text-align: center;
    }

    .closeIcon {
      cursor: pointer;
    }
  }
  .content {
    margin-top: 60px;

    @media screen and (min-width: vars.$extra-large) {
      margin-top: initial;
    }
  }
}
