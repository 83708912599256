.overlay {
  position: fixed;
  overflow: hidden;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.content {
  display: flex;
  justify-content: center;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1001;
}
