@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  align-items: center;
  border: none;
  border-radius: 4px;
  color: colors.$white;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  height: 45px;
  line-height: 1.313rem;
  font-stretch: condensed;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  margin-top: 8px;
  overflow: hidden;
  width: 100%;

  &:disabled {
    opacity: 0.2;
  }

  // Google Color
  background-color: #4185f4;
}

.buttonIcon {
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 0 0 42px;
  height: 45px;
  justify-content: center;
}

.buttonText {
  flex: 1 1 100%;
  justify-content: center;
}
