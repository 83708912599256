@use '../../styleguide/vars.scss';

.modalSocialButtons header span svg {
  cursor: pointer;
}

.modalSocialButtons main {
  button {
    height: 35px;

    @media screen and (min-width: vars.$medium) {
      height: 45px;
    }

    span:first-of-type {
      height: 35px;

      @media screen and (min-width: vars.$medium) {
        height: 45px;
      }
    }
  }
}

.content {
  margin-top: vars.$gutter;
}