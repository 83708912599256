@use '../../styleguide/vars.scss';

.modalSocialButtons header span svg {
  cursor: pointer;
}

.modalSocialButtons main {
  button {
    height: 35px;

    @media screen and (min-width: vars.$medium) {
      height: 45px;
    }

    span:first-of-type {
      height: 35px;

      @media screen and (min-width: vars.$medium) {
        height: 45px;
      }
    }
  }
}

.logo {
  height: 180px;
  margin-top: 15px;
  width: auto;
  object-fit: contain;

  @media screen and (min-width: vars.$medium) {
    height: 200px;
    margin: 30px 0;
  }

  @media screen and (min-width: vars.$extra-large) {
    height: 220px;
  }
}

.content {
  margin-top: vars.$gutter;
  text-align: center;
}